import axios, { AxiosPromise } from 'axios';
import { IUserToken } from '../../../@types/model/right/userToken/userTokenModels';

export default class AuthHttpService {
    private static readonly PATH = '/Authorisation'

    public static signUp = (employeeNumber : string, password : string) : AxiosPromise<IUserToken> => {
        return axios.post(`${API_URL}/v1/Authorisation/SignUp`, {
            employeeNumber,
            password,
        });
    }

    public static googleLogin = (code : string) : AxiosPromise<IUserToken> => {
        return axios.post(`${API_URL}/v1/Authorisation/LoginGoogle`, {
            code,
        });
    }

    public static login = (username : string, password : string) : AxiosPromise<IUserToken> => {
        return axios.post(`${API_URL}/v1/Authorisation/Login`, {
            username,
            password,
        });
    }

    public static getSession = () : AxiosPromise<IUserToken> => {
        return axios.get(`${API_URL}/v1${this.PATH}/GetSession`);
    }
}
