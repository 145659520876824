import { createReducer } from '@reduxjs/toolkit';
import RightActions from './actions';
import { IUser } from '../../@types/model/right/user/userModels';

export interface IRightState {
    isLoading : boolean;
    users : Record<number, IUser> | null;
}

const initialState = {
    isLoading: false,
    users: null,
};

const rightReducer = createReducer<IRightState>(initialState, (builder) => {
    builder.addCase(RightActions.setIsLoading, (state, action) => {
        state.isLoading = action.payload;
    });
    builder.addCase(RightActions.setUsers, (state, action) => {
        state.users = action.payload;
    });
});

export default rightReducer;