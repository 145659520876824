import axios, { AxiosPromise } from 'axios';
import { IUser, IUserUpsert } from '../../../@types/model/right/user/userModels';
import { IUserToken } from '../../../@types/model/right/userToken/userTokenModels';

export default class UserHttpService {
    private static readonly PATH = '/User'

    public static getList = async () : Promise<Array<IUser>> => {
        const result = await axios.get(`${API_URL}/v1/User/GetList`);
        return result.data;
    }

    public static usersUpsert = async (upsertData : Array<IUserUpsert>) : Promise<Array<IUser>> => {
        const result = await axios.post(`${API_URL}/v1/User/Upsert`, upsertData);
        return result.data;
    }

    public static setEmployeeNr = (employeeNr : string) : AxiosPromise<IUserToken> => {
        return axios.post(`${API_URL}/v1/User/SetEmployeeNumber`, null, {
            params: {
                employeeNr,
            },
        });
    }

    public static linkGoogleAccount = (code : string) : AxiosPromise<IUser | null> => {
        return axios.post(`${API_URL}/v1/User/LinkGoogleAccountToUser`, {
            code
        });
    }

    public static logout = () : AxiosPromise => {
        return axios.post(`${API_URL}/v1${this.PATH}/LogoutUser`);
    }

    public static logoutUsers = (userIds : Array<number>) : AxiosPromise => {
        return axios.post(`${API_URL}/v1${this.PATH}/LogoutAllUsers`, null, {
            params: {
                userIds,
            }
        });
    }

    public static requestForgottenPasswordEmail(email : string) : AxiosPromise<void> {
        return axios.post(`${API_URL}/v1${this.PATH}/RequestPasswordReset`, null, {
            params: {
                email,
            }
        });
    }

    public static resetPassword(oldPassword : string, newPassword : string, recoveryCode : string) : AxiosPromise<boolean> {
        return axios.post(`${API_URL}/v1${this.PATH}/ResetUserPassword`, null, {
            params: {
                oldPassword,
                newPassword,
                recoveryCode,
            }
        });
    }

    public static userDelete = (userId : number) : Promise<void> => {
        return axios.delete(`${API_URL}/v1${this.PATH}/Delete`, {
            params: { userId },
        });
    }
}
