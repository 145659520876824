import { CodeResponse, useGoogleLogin } from '@react-oauth/google';
import React from 'react';
import generalFunctions from '../../../store/general/thunk';
import { Tooltip } from '@material-ui/core';
import { PillButton } from '@zz2/zz2-ui';
import { IUser } from '../../../@types/model/right/user/userModels';
import RightThunks from '../../../store/rights/thunk';

const iconsLocation = `${ASSET_BASE}/assets/icons`;

export interface IGoogleLoginSuccess extends Omit<CodeResponse, 'error' | 'error_description' | 'error_uri'> {}

interface IGoogleLinkAccountButtonProps {
    currentUser : IUser | null;
    onClose : () => void;
}

const GoogleLinkAccountButton = (props : IGoogleLinkAccountButtonProps) : React.ReactElement => {
    const onSuccess = async (response : IGoogleLoginSuccess) : Promise<void> => {
        
        RightThunks.linkGoogleAccountToUser(response.code);

        props.onClose();
    };

    const onFailure = () : void => {
        generalFunctions.showErrorSnackbar({ defaultMessage: 'An error occurred while performing Google authentication.'});
    };

    const linkGoogleAccount = useGoogleLogin({
        onSuccess: onSuccess,
        onError: onFailure,
        flow: 'auth-code',
        scope: 'profile email openid',
    });

    return (
        <Tooltip title={props.currentUser?.email ? 'Account Already Linked.' : ''}>
            <div>
                <PillButton
                    onClick={linkGoogleAccount}
                    text={'LINK GOOGLE ACCOUNT'}
                    className={'w250 jcsb'}
                    disabled={!!props.currentUser?.email}
                    icon={<img src={`${iconsLocation}/google_icon.svg`}/>}
                />
            </div>
        </Tooltip>
    );
};

export default GoogleLinkAccountButton;
