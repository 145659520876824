import { ProjectDialog } from '@zz2/zz2-ui';
import React from 'react';
import { useAppSelector } from '../../@types/redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import GoogleLinkAccountButton from '../component/button/GoogleLinkAccountButton';
import { IUser } from '../../@types/model/right/user/userModels';

interface IUserSettingsProps {
    isOpen : boolean;
    onClose : () => void;
    currentUser : IUser;
}

const UserSettings = (props : IUserSettingsProps) : React.ReactElement => {
    const isLoading = useAppSelector(x => x.right.isLoading);

    return (
        <ProjectDialog
            title={'User Settings'}
            isOpen={props.isOpen}
            isLoadingCircular={isLoading}
            maxWidth={'xs'}
            onClose={props.onClose}>
            <div className={'fdc h100 flx1 aic jcc m10 p10'}>
                <div className={'fdr aic'}>
                    <GoogleOAuthProvider clientId={OAUTH_CLIENT_ID}>
                        <GoogleLinkAccountButton
                            onClose={props.onClose}
                            currentUser={props.currentUser}
                        />
                    </GoogleOAuthProvider>
                </div>
            </div>
        </ProjectDialog >
    );
};

export default UserSettings;