import { hashedHistory } from '../index';

const push = hashedHistory.push;
const goBack = hashedHistory.goBack;
const replace = hashedHistory.replace;

const navBack = () => goBack();
const navPush = (pathname : string) : void => push(pathname);
const navReplace = (pathname : string) : void => replace(pathname);

const navActions = {
    navBack,
    navPush,
    navReplace
};

export default navActions;
