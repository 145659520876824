import { IOptionType } from '@zz2/zz2-ui';
import { FilterDepthEnum } from './@types/enum/filterDepth';
import { IFilterDepthOptionType } from './@types/model/filterDepthOptionType';
import { RightGrantsEnum } from './@types/enum/rightGrantsEnum';

export const QUERY_DATA_STALE_TIME = 30 * 60 * 1000;
export const SNACKBAR_WIDTH = 400;

export const FILTER_DEPTH_OPTIONS : Array<IFilterDepthOptionType> = [
    { label: 'Year', value: 0, enumerable: FilterDepthEnum.Year },
    { label: 'Month', value: 1, enumerable: FilterDepthEnum.Month },
    { label: 'Day', value: 2, enumerable: FilterDepthEnum.Day },
    { label: 'Hour', value: 3, enumerable: FilterDepthEnum.Hour },
    { label: 'Minute', value: 4, enumerable: FilterDepthEnum.Minute }, 
];

export const FILTER_INTERVAL_OPTIONS : Array<IOptionType> = [
    { label: '1 Unit', value: 1 },
    { label: '5 Units', value: 5 },
    { label: '10 Units', value: 10 },
    { label: '15 Units', value: 15 },
    { label: '20 Units', value: 20 },
    { label: '30 Units', value: 30 }, 
];

export const RIGHT_GRANT_LEVELS : Record<string, number> = {
    'View': RightGrantsEnum.View,
    'Add': RightGrantsEnum.Add,
    'Edit': RightGrantsEnum.Edit,
    'Delete': RightGrantsEnum.Delete,
    'Admin': RightGrantsEnum.Admin,
};