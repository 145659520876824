import React, { useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../@types/redux';
import { useLocation } from 'react-router';
import AuthThunks from '../../store/auth/thunk';
import GeneralActions from '../../store/general/actions';
import { NavBar, NavDrawer, lowercase } from '@zz2/zz2-ui';
import { VERSION } from '../../version';
import UserSettings from './UserSettings';
import Routes from './Routes';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { IUser } from '../../@types/model/right/user/userModels';
import { IRight } from '../../@types/model/right/right/right';
import { Link } from 'react-router-dom';
import SideMenuItem from '../component/menu/SideMenuItem';
import lodash from 'lodash';
import EnvHelper from '../../service/helper/envHelper';

const NavFrame = () : React.ReactElement => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const currentUser = useAppSelector<IUser | undefined>(x => x.auth.session?.user);
    const isNavDrawerOpen = useAppSelector(x => x.general.isNavDrawerOpen);

    const [isUserSettingsDialogOpen, setIsUserSettingsDialogOpen] = useState<boolean>(false);

    /*================================================================================================================
     *                                                  Handler Methods
     * ==============================================================================================================*/

    const signOut = () : void => {
        dispatch(GeneralActions.setNavDrawer(false));
        dispatch(AuthThunks.logout());
    };

    const openUserSettings = () => {
        setIsUserSettingsDialogOpen(true);
    };

    const closeUserSettings = () => {
        setIsUserSettingsDialogOpen(false);
    };

    const openDrawer = () : void => {
        dispatch(GeneralActions.setNavDrawer(true));
    };

    const closeDrawer = () : void => {
        dispatch(GeneralActions.setNavDrawer(false));
    };


    /*================================================================================================================
     *                                                  Memos
     * ==============================================================================================================*/

    const userSettings = useMemo<React.ReactElement>(() => {
        if (currentUser) {
            return <UserSettings 
                isOpen={isUserSettingsDialogOpen}
                currentUser={currentUser}
                onClose={closeUserSettings}
            />;
        }

        return <div></div>;
    }, [isUserSettingsDialogOpen]);

    /*================================================================================================================
     *                                                 Memos
     * ==============================================================================================================*/

    const currentUserRights = useMemo(() => {
        return currentUser?.userRights.map(x => x.right);
    }, []);

    const userCurrentRight = useMemo<IRight | undefined>(() => {
        return currentUserRights?.filter(x => x.isActive)?.find(x => x.url === location.pathname);
    }, [currentUser, location.pathname]);


    const breadcrumbs = useMemo<Array<{ name : string; url : string }>>(() => {
        const breadcrumbList : Array<{ name : string; url : string }> = [];

        if (userCurrentRight) {
            let currentRight : IRight | undefined = userCurrentRight;

            do {
                breadcrumbList.push({ name: currentRight.name, url: currentRight.url });
                currentRight = currentUserRights?.find(x => x.id === currentRight?.breadcrumbParentId);
            } while (currentRight);
        }
        return breadcrumbList.reverse();
    }, [currentUser, location, currentUserRights, userCurrentRight]);


    /*================================================================================================================
     *                                                  Render
     * ==============================================================================================================*/

    const isParentOfCurrentPage = (parentId : number, rights ?: Array<IRight>, pathname ?: string) : boolean => {
        const currentPageRight = rights?.find(x => x.url === pathname);
        const parentRight = rights?.find(x => x.id === parentId);

        if (currentPageRight?.parentId) {
            return (currentPageRight.parentId === parentId) || (!!parentRight?.parentId && isParentOfCurrentPage(parentRight.parentId, rights, pathname));
        }
        return false;
    };

    const renderListSection = (right : IRight, children : Array<IRight>, rights ?: Array<IRight>, pathname ?: string) : JSX.Element => {
        return (
            <SideMenuItem
                className={'pt10'}
                paddingLeft={0}
                icon={<img className={'h30 w30'} src={`${ASSET_BASE}/assets/icons/${lowercase(right.name).replace(/ /g, '_')}.svg`}/> }
                title={right.name}
                key={'section_' + right.id}
                boldTitle={isParentOfCurrentPage(right.id, rights, pathname)}>
                {children.map(x => renderListItem(x, false, pathname))}
            </SideMenuItem>
        );
    };

    const renderListItem = (right : IRight, hasIcon : boolean, pathname ?: string) : JSX.Element => {
        return (
            <Link key={`link ${right.url}`} to={right.url} onClick={closeDrawer} className={'tdus cb'}>
                <ListItem button key={right.guid}>
                    {
                        hasIcon ?
                            <ListItemIcon>
                                {<img className={'h30 w30 mt8 mb8'} src={`${ASSET_BASE}/assets/icons/${lowercase(right.name).replace(/ /g, '_')}.svg`} />}
                            </ListItemIcon>
                            :
                            <div className={'h30 w30'} />
                    }
                    <ListItemText disableTypography className={`${pathname === right.url ? 'fw550' : ''} pl0`} inset primary={right.name} />
                </ListItem>
            </Link>
        );
    };

    const sections = useMemo<Array<React.ReactElement>>(() => {
        const uniqueRights = lodash.uniqBy(currentUser?.userRights.map(x => x.right), x => x.id);
    
        return lodash.chain(uniqueRights)
            .filter(x => x.isOnNavDrawer && x.isActive && !x.parentId)
            .filter(x => !!x.sectionOrder)
            .sortBy(x => x.sectionOrder)
            .map((x) => {
                return {
                    section: x,
                    children: lodash.chain(uniqueRights)
                        .filter(y => y.isOnNavDrawer && y.isActive && y.parentId === x.id)
                        .filter(y => !!y.pageOrder)
                        .sortBy(y => y.sectionOrder).value(),
                };
            }).map((x) => {
                return x.section.isPage
                    ? renderListItem(x.section, true, location.pathname)
                    : renderListSection(x.section, x.children, currentUserRights, location.pathname);
            }).value();

    }, [currentUserRights, location.pathname]);

    return (
        <div className={'fdc hfill'}>
            <NavBar
                env={EnvHelper.getEnvName()}
                version={VERSION.version}
                currentUser={currentUser}
                signOut={signOut}
                breadcrumbs={breadcrumbs}
                openDrawer={openDrawer}
                closeDrawer={closeDrawer}
                openUserSettings={openUserSettings}
                closeUserSettings={closeUserSettings}
                disabled={false}       
                userSettingsDialog={userSettings}
                isLatestVersion={true}             
            />
            <div className={'fdr flx1 oyh'}>
                <NavDrawer
                    env={EnvHelper.getEnvName()} 
                    path={location.pathname}
                    currentUser={currentUser}
                    isOpen={isNavDrawerOpen}
                    isLoading={false}
                    sections={sections}
                    signOut={signOut}
                    closeDrawer={closeDrawer}
                    onHelpClick={() => console.log('help')}
                />
                <Routes />            
            </div>
        </div>
    );
};

export default NavFrame;