import generalFunctions from '../general/thunk';
import rightActions from './actions';
import UserHttpService from '../../service/http/right/userHttpService';
import * as localStorageService from '../../service/localStorageService';
import navActions from '../nav/actions';
import authActions from '../auth/actions';
import { createAppAsyncThunk } from '../../@types/redux';
import { IUserUpsert } from '../../@types/model/right/user/userModels';
import { ArrayHelper } from '@zz2/zz2-ui';

export default class RightThunks {
    /**
     * Retrieves the list of users from the API, updating the redux state once complete.
     *
     * @returns {Promise<Array<IUser> | null>}
     */
    public static getUserList = createAppAsyncThunk(
        'right/getUserList',
        async (_, ThunkApi) => {
            try {
                ThunkApi.dispatch(rightActions.setIsLoading(true));
    
                const res = await UserHttpService.getList();
    
                ThunkApi.dispatch(rightActions.setUsers(ArrayHelper.toRecord(res, 'id')));
    
                return res;
            } catch (e) {
                generalFunctions.showErrorSnackbar({ defaultMessage: 'An error occurred while loading users.', ex: e });
                return null;
            } finally {
                ThunkApi.dispatch(rightActions.setIsLoading(false));
            }
        }
    )
    

    /**
     * Upsert a list of user, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {Array<IUserUpsert>} upsertData
     * @returns {Promise}
     */
    public static upsertUser = createAppAsyncThunk(
        'rights/upsertUser',
        async (params : { upsertData : Array<IUserUpsert> }, ThunkApi) => {
            try {
                ThunkApi.dispatch(rightActions.setIsLoading(true));
    
                await UserHttpService.usersUpsert(params.upsertData);
                generalFunctions.showSuccessSnackbar(`${params.upsertData.length > 1 ? 'Entries' : 'Entry'} saved successfully.`);
    
                await ThunkApi.dispatch(RightThunks.getUserList());
            } catch (e) {
                generalFunctions.showErrorSnackbar({ defaultMessage: `An error occurred while saving the ${params.upsertData.length > 1 ? 'users' : 'user'}.`, ex: e });
            } finally {
                ThunkApi.dispatch(rightActions.setIsLoading(false));
            }
        }
    )
    

    /**
     * Links google account to user, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {string} code
     * @returns {Promise<IUser | null>}
     */
    public static linkGoogleAccountToUser = createAppAsyncThunk(
        'rights/linkGoogleAccountToUser',
        async (code : string, ThunkApi) => {
            try {
                ThunkApi.dispatch(rightActions.setIsLoading(true));
    
                const res = await UserHttpService.linkGoogleAccount(code);
                if (res.data) {
                    const state = ThunkApi.getState();
                    const session = state.auth.session;
    
                    if (session) {
                        const updatedSession = {
                            ...session,
                            user: res.data,
                        };
    
                        ThunkApi.dispatch(authActions.setSession(updatedSession));
                        await localStorageService.setLocalStorageSession(updatedSession);
                    }
    
                    generalFunctions.showSuccessSnackbar('Google account successfully linked.');
    
                    await ThunkApi.dispatch(RightThunks.getUserList());
    
                    return res.data;
                }
    
                return null;
            } catch (e) {
                generalFunctions.showErrorSnackbar({ defaultMessage: 'An error occurred while linking google account.', ex: e });
                return null;
            } finally {
                ThunkApi.dispatch(rightActions.setIsLoading(false));
            }
        }
    )
    

    /**
     * Performs request for password reset email.
     *
     * @param email
     */
    public static requestForgottenPassword = createAppAsyncThunk(
        'rights/requestForgottenPassword',
        async (params : { email : string }, ThunkApi) => {
            try {
                ThunkApi.dispatch(rightActions.setIsLoading(true));
    
                await UserHttpService.requestForgottenPasswordEmail(params.email);
    
                generalFunctions.showSuccessSnackbar('Password recovery submitted, please check your email.');
                return true;
            } catch (e) {
                ThunkApi.dispatch(rightActions.setIsLoading(false));
                generalFunctions.showErrorSnackbar({ defaultMessage: 'An error occurred while recovering password.', ex: e });
                return false;
            }
        }
    )
    

    /**
     * Performs request for password reset using recovery code received in email.
     *
     * @param oldPassword
     * @param newPassword
     * @param recoveryCode
     */
    public static passwordReset = createAppAsyncThunk(
        'rights/passwordReset',
        async (params : { oldPassword : string; newPassword : string; recoveryCode : string }, ThunkApi) => {
            try {
                ThunkApi.dispatch(rightActions.setIsLoading(true));
    
                await UserHttpService.resetPassword(params.oldPassword, params.newPassword, params.recoveryCode);
    
                generalFunctions.showSuccessSnackbar('Password Change Successful.');
                ThunkApi.dispatch(rightActions.setIsLoading(false));
                await localStorageService.setLocalStorageSession(null);
                navActions.navReplace('/login');
            } catch (e) {
                generalFunctions.showErrorSnackbar({ defaultMessage: 'An error occurred while submitting password reset request.', ex: e });
                ThunkApi.dispatch(rightActions.setIsLoading(false));
            }
        }
    )
    

    /**
     * Deletes a user, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {number} userId
     * @returns {Promise<IUser | null>}
     */
    public static deleteUser = createAppAsyncThunk(
        'rights/deleteUser',
        async (params : { userId : number }, ThunkApi) => {
            try {
                ThunkApi.dispatch(rightActions.setIsLoading(true));
    
                await UserHttpService.userDelete(params.userId);
    
                generalFunctions.showSuccessSnackbar('Entry successfully deleted.');
    
                await ThunkApi.dispatch(RightThunks.getUserList());
    
                return null;
            } catch (e) {
                generalFunctions.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting the user.', ex: e });
                return null;
            } finally {
                ThunkApi.dispatch(rightActions.setIsLoading(false));
            }
        }
    )
    
}