import React, { Suspense, lazy } from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps, Switch } from 'react-router';
import { useAppSelector } from '../../@types/redux';
import { Loader, Screen } from '@zz2/zz2-ui';

const PrivateRoute = (props : RouteProps) : JSX.Element => {
    const session = useAppSelector(x => x.auth.session);
    const { component, ...rest } = props;

    const render = (routerProps : RouteComponentProps<any>) : React.ReactNode => {
        if (session) {
            if (!component) return;

            const Component = component;
            return <Component {...routerProps} />;
        }

        return <Redirect to={{ pathname: '/login', state: { from: routerProps.location } }} />;
    };

    return (<Route {...rest} render={render} />);
};

{/* Route Imports */}
const Home = lazy(() => import('../home/Home'));
const MasterDataHome = lazy(() => import('../masterData/MasterDataHome'));
const PortTypeManager = lazy(() => import('../masterData/portType/PortTypeManager'));
const SensorTypeManager = lazy(() => import('../masterData/sensorType/SensorTypeManager'));
const SensorTypeCategoryManager = lazy(() => import('../masterData/sensorTypeCategory/SensorTypeCategoryManager'));
const UnitOfMeasureManager = lazy(() => import('../masterData/unitOfMeasure/UnitOfMeasureManager'));
const UnitOfMeasureTypeManager = lazy(() => import('../masterData/unitOfMeasureType/unitOfMeasureTypeManager'));
const DeviceManager = lazy(() => import('../device/DeviceManager'));
const AccumulatorManager = lazy(() => import('../accumulators/AccumulatorManager'));
const RawDataManager = lazy(() => import('../rawData/RawDataOverview'));
const DataClusterManager = lazy(() => import('../dataCluster/DataClusterManager'));
const PlaceManager = lazy(() => import('../masterData/place/PlaceManager'));
const DeviceOverview = lazy(() => import('../device/DeviceOverview'));
const UserManager = lazy(() => import('../rights/userManagement/UserManager'));
const DevicePortDataDetail = lazy(() => import('../device/components/devicePort/DevicePortsDetail'));

const RightsManagement = lazy(() => import('../rights/rightsManagement/RightsManager'));

const Routes = () : React.ReactElement => {
    return (
        <Suspense fallback={<Screen><Loader/></Screen>}>
            <Switch>
                <Route
                    path={'/'} exact
                    render={() => <Redirect from={'/'} to={{ pathname: '/home' }} />}
                />
                
                <PrivateRoute exact path='/home' component={Home} />

                {/* MasterData */}
                <PrivateRoute exact path='/masterData' component={MasterDataHome} />
                <PrivateRoute exact path='/masterData/portType' component={PortTypeManager} />
                <PrivateRoute exact path='/masterData/sensorType' component={SensorTypeManager} />
                <PrivateRoute exact path='/masterData/sensorTypeCategory' component={SensorTypeCategoryManager} />
                <PrivateRoute exact path='/masterData/unitOfMeasure' component={UnitOfMeasureManager} />
                <PrivateRoute exact path='/masterData/unitOfMeasureType' component={UnitOfMeasureTypeManager} />
                <PrivateRoute exact path='/masterData/place' component={PlaceManager} />
                
                {/* Device */}
                <PrivateRoute exact path='/device/deviceManagement' component={DeviceManager} />
                <PrivateRoute exact path='/device/deviceOverview' component={DeviceOverview} />
                <PrivateRoute exact path='/device/deviceOverview/:deviceId?' component={DevicePortDataDetail} />
                <PrivateRoute exact path='/device/deviceRawData' component={RawDataManager} />

                {/* Accumulators */}
                <PrivateRoute exact path='/accumulatorManagement' component={AccumulatorManager} />
                
                {/* DataCluster */}
                <PrivateRoute exact path='/dataCluster' component={DataClusterManager} />

                {/* Rights */}
                <PrivateRoute exact path='/rights/userManagement' component={UserManager} />
                <PrivateRoute exact path='/rights/rightsManagement' component={RightsManagement} />
                
            </Switch>
        </Suspense>
    );
};

export default Routes;