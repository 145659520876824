import { createAction } from '@reduxjs/toolkit';
import { IUser } from '../../@types/model/right/user/userModels';
import { withPayloadType } from '@zz2/zz2-ui';

export default class RightActions {
    public static setIsLoading = createAction('RIGHT_SET_LOADING', withPayloadType<boolean>());
    public static setUsers = createAction('RIGHT_SET_USER_LIST', withPayloadType<Record<number, IUser>>());   
    public static logout = createAction('RIGHT_LOGOUT', withPayloadType<void>());
}


