import authActions from './actions';
import * as localStorageService from '../../service/localStorageService';
import AuthHttpService from '../../service/http/right/authHttpService';
import UserHttpService from '../../service/http/right/userHttpService';
import AuthActions from './actions';
import { createAppAsyncThunk } from '../../@types/redux';
import GeneralThunks from '../general/thunk';

export default class AuthThunks {
    /**
     * Performs sign up request with API then sets the interceptor (using auth token) and auth state accordingly. Kicks off
     * the setup for local storage service to store the session and, in turn, firing its callback (that is set during app
     * init).
     *
     * @param employeeNumber
     * @param password
     */
    public static signUp = createAppAsyncThunk(
        'auth/signUp',
        async (params : {
            employeeNumber : string;
            password : string;
        }, ThunkApi) => {
            try {
                ThunkApi.dispatch(AuthActions.setLoggingIn(true));
    
                const res = await AuthHttpService.signUp(params.employeeNumber, params.password);
                await localStorageService.setLocalStorageSession(res.data);
                    
            } catch (e) {
                ThunkApi.dispatch(AuthActions.setLoggingIn(false));
                ThunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while signing up.', ex: e }));

            } finally {
                ThunkApi.dispatch(AuthActions.setLoggingIn(false));
            }
        }
    );

    /**
     * Performs google log in request with API then sets the interceptor (using auth token) and auth state accordingly. Kicks off
     * the setup for local storage service to store the session and, in turn, firing its callback (that is set during app
     * init).
     *
     * @param idToken
     */
    public static googleLogin = createAppAsyncThunk(
        'auth/googleLogin',
        async (params : {
            code : string;
        }, ThunkApi) => {
            try {
                ThunkApi.dispatch(AuthActions.setLoggingIn(true));

                const res = await AuthHttpService.googleLogin(params.code);

                await localStorageService.setLocalStorageSession(res.data);
    
                ThunkApi.dispatch(AuthActions.setSession(res.data));

                ThunkApi.dispatch(GeneralThunks.showSuccessSnackbar('Success'));
                
            } catch (e) {
                ThunkApi.dispatch(AuthActions.setLoggingIn(false));
                ThunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while logging in.', ex: e }));

            } finally {
                ThunkApi.dispatch(AuthActions.setLoggingIn(false));   
            }
        }
    )

    /**
     * Performs manuel log in request with API then sets the interceptor (using auth token) and auth state accordingly. Kicks off
     * the setup for local storage service to store the session and, in turn, firing its callback (that is set during app
     * init).
     *
     * @param username
     * @param password
     */
    public static manualLogin = createAppAsyncThunk(
        'auth/manualLogin',
        async (params : {
            username : string;
            password : string;
        }, ThunkApi) => {
            try {
                ThunkApi.dispatch(AuthActions.setLoggingIn(true));
    
                const res = await AuthHttpService.login(params.username, params.password);
                await localStorageService.setLocalStorageSession(res.data);

                ThunkApi.dispatch(AuthActions.setSession(res.data));

                ThunkApi.dispatch(GeneralThunks.showSuccessSnackbar('Login Success'));
    
            } catch (e) {
                console.log('the current error', e);
                ThunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while logging in.', ex: e }));

            } finally {
                ThunkApi.dispatch(AuthActions.setLoggingIn(false));
            }
        }
    )


    /**
     * Performs log out request with API then sets the auth state accordingly.
     * 
     * @param userId
     */
    public static logout = createAppAsyncThunk(
        'auth/logout',
        async (params, ThunkApi) => {
            try {
                ThunkApi.dispatch(AuthActions.setLoggingOut(true));

                await UserHttpService.logout();
    
                await localStorageService.setLocalStorageSession(null);
                await localStorageService.setLocalStorageUserData(null);
    
                ThunkApi.dispatch(AuthActions.logout());

                ThunkApi.dispatch(GeneralThunks.showSuccessSnackbar('Logout Success'));

            } catch (e) {
                ThunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while logging out.', ex: e }));
                await localStorageService.setLocalStorageSession(null);
                ThunkApi.dispatch(AuthActions.setLoggingOut(false));

            } finally {
                ThunkApi.dispatch(AuthActions.setLoggingOut(false));
            }
        }
    )

    /**
     * Performs log out request with API for the provided user IDs then sets the auth state accordingly.
     * 
     * @param userIds
     */
    public static logoutUsers = createAppAsyncThunk(
        'auth/logoutUsers',
        async (params : {
            currentUserId : number;
            userIds : Array<number>;
        }, ThunkApi) => {
            try {    
                await UserHttpService.logoutUsers(params.userIds);
    
                if (params.userIds.find(x => x === params.currentUserId)) {
                    await localStorageService.setLocalStorageSession(null);
                    await localStorageService.setLocalStorageUserData(null);
        
                    ThunkApi.dispatch(authActions.logout());
                }

            } catch (e) {
                ThunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while logging out users.', ex: e }));
                await localStorageService.setLocalStorageSession(null);
                ThunkApi.dispatch(AuthActions.setLoggingOut(false));
            }
        }
    )
}
